import * as React from "react"

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

class MultiSelect extends React.Component<any, any> {

	constructor(props: any) {
		super(props);
		console.log(props)
		this.state = {
			left: props.left || [],
			right: props.right || [],
			allowEmptyGroups: props.allowEmptyGroups || false
		}
		this.handleToggle = this.handleToggle.bind(this)
		this.handleAllRight = this.handleAllRight.bind(this)
		this.handleAllLeft = this.handleAllLeft.bind(this)
	}

	fireChange(){
		if (this.props.changeEvent){
			this.props.changeEvent(this.state.left, this.state.right)
		}
	}

	async toggleRightChange(inRight: any){
		await this.setState({
			left: this.state.left.concat(inRight[0]),
			right: this.state.right.filter((d: any) => d.dataField !== inRight[0].dataField)
		});
		this.fireChange()
	}

	async toggleLeftChange(inLeft: any){
		await this.setState({
			right: this.state.right.concat(inLeft[0]),
			left: this.state.left.filter((d: any) => d.dataField !== inLeft[0].dataField)
		});
		this.fireChange()
	}

	handleToggle(value: any) {

		// Checked in Left
		const inLeft = this.state.left.filter((d: any) => d.dataField === value.dataField)
		if (inLeft.length > 0){
			this.toggleLeftChange(inLeft)
		}

		// Checked in Right
		const inRight = this.state.right.filter((d: any) => d.dataField === value.dataField)
		if (inRight.length > 0 && this.state.right.length > 1){
			this.toggleRightChange(inRight)
		}	
	}

 	async handleAllLeft(){
		await this.setState({ 
			left : this.state.left.concat(this.state.right.splice(1)),
			right : this.state.allowEmptyGroups ? [] : this.state.right.splice(0, 1)
		})
		this.fireChange()
	};

	async handleAllRight(){
		await this.setState({ 
			left : [],
			right : this.state.right.concat(this.state.left)
		})
		this.fireChange()
	};

	customList(items: any[]){
		return (
			<Paper>
			  <List dense component="div" role="list">
			    {items.map((value: any) => {
			      const labelId = `transfer-list-item-${value.dataField}-label`;

			      return (
			        <ListItem key={value.dataField} role="listitem" button onClick={e => {
				        	this.handleToggle(value)
						}}>
						<ListItemText id={labelId} primary={value.text} />
			        </ListItem>
			      );
			    })}
			  </List>
			</Paper>
		)
	}

	render() {
    
        return (
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item>{this.customList(this.state.left)}</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<Button variant="outlined" size="small" onClick={this.handleAllRight} 
							disabled={this.state.left.length === 0} aria-label="move all right"
						>≫</Button>
						<Button variant="outlined" size="small" onClick={this.handleAllLeft}
							disabled={this.state.right.length === 0} aria-label="move all left"
						>≪</Button>
					</Grid>
			</Grid>
				<Grid item>{this.customList(this.state.right)}</Grid>
			</Grid>
        )
    }
}
export default MultiSelect;
