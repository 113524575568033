import React from "react"

import { Grid } from '@mui/material';

import Table from "../../components/custom/Table";
import Button from '../../components/custom/AddButton'

import { connect } from "react-redux";
import { getReceiptTable } from "../../redux/actions/receipts/index";

import { Order } from '../../types/order'
import Utils from "../../utils/TableUtils";

const ReceiptTable: React.FC<any> = (props) => {
    
	React.useEffect(() => {
		props.getReceiptTable();
	}, [])

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item xs>
				<Button text="Nueva remesa" href={'/receipts'} />
			</Grid>
			<Grid container item xs>
				<Table
					data={props.table_data}
					page={props.page}
					size={props.size}
					total={props.total}
					columns={Utils.getReceiptColumns()}
					showColumns={false}
					onPaginationFilterChange={(page: number, size: number, filters:any, sort: string, sortDirection: Order) => {
						props.getReceiptTable(page, size, filters, sort, sortDirection);
					}}
					onRowClick={(id: number) => {
						window.open("/receipts/" + id, "_self");
					}}
				/>
			</Grid>
		</Grid>
	)
}

function mapStateToProps(state: any) {
	return {
		table_data: state.table_reducer.table_data,
		page: state.table_reducer.page,
		size: state.table_reducer.size,
		total: state.table_reducer.total
	};
}

// Object of action creators
const mapDispatchToProps = {
	getReceiptTable
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReceiptTable);


