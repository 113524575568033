export const ADD_SPORTSCHOOL = "ADD_SPORTSCHOOL";
export const LOAD_SPORTSCHOOLS = "LOAD_SPORTSCHOOLS";
export const LOAD_SPORTSCHOOL_TABLE = "LOAD_SPORTSCHOOL_TABLE";
export const GET_SPORTSCHOOL = "GET_SPORTSCHOOL";
export const DELETE_SPORTSCHOOL = "DELETE_SPORTSCHOOL";
export const DOWNLOAD_SPORTSCHOOLS = "DOWNLOAD_SPORTSCHOOLS";
export const GET_SPORTSCHOOL_FEE_TYPES = "GET_SPORTSCHOOL_FEE_TYPES";
export const GET_SPORTSCHOOL_COLLECTIONS = "GET_SPORTSCHOOL_COLLECTIONS";

export const ADD_ATHLETE = "ADD_ATHLETE";
export const ADD_ATHLETE_SPORTDATA = "ADD_ATHLETE_SPORTDATA";
export const LOAD_ATHLETES = "LOAD_ATHLETES";
export const LOAD_ATHLETE_TABLE = "LOAD_ATHLETE_TABLE";
export const LOAD_PAYMENT_ATHLETE_TABLE = "LOAD_PAYMENT_ATHLETE_TABLE";
export const LOAD_HISTORIC_TABLE = "LOAD_HISTORIC_TABLE";
export const GET_ATHLETE = "GET_ATHLETE";
export const DELETE_ATHLETE = "DELETE_ATHLETE";
export const GET_ATHLETE_BY_CODE = "GET_ATHLETES_BY_CODE";
export const GET_ATHLETES_BY_PHONE = "GET_ATHLETES_BY_PHONE";
export const GET_ATHLETES_BY_SURNAMES = "GET_ATHLETES_BY_SURNAMES";
export const GET_ATHLETE_FEE = "GET_ATHLETE_FEE";
export const GET_ATHLETE_OBSERVATIONS = "GET_ATHLETE_OBSERVATIONS";
export const GET_ATHLETE_RECEIPTS = "GET_ATHLETE_RECEIPTS";
export const SET_ATHLETE_RECEIPT_STATUS = "SET_ATHLETE_RECEIPT_STATUS";
export const GET_ATHLETE_HISTORIC = "GET_ATHLETE_HISTORIC";
export const LOAD_ATHLETE_SPORTDATA_TABLE = "LOAD_ATHLETE_SPORTDATA_TABLE";
export const LOAD_DUPLICATED_ATHLETES_TABLE = "LOAD_DUPLICATED_ATHLETES_TABLE";
export const DOWNLOAD_ATHLETES = "DOWNLOAD_ATHLETES";
export const DOWNLOAD_HISTORIC = "DOWNLOAD_HISTORIC";
export const REGISTER_ATHLETE = "REGISTER_ATHLETE";
export const UNREGISTER_ATHLETE = "UNREGISTER_ATHLETE";

export const LOAD_INCIDENCE_TABLE = "LOAD_INCIDENCE_TABLE";
export const ADD_INCIDENCE = "ADD_INCIDENCE";
export const GET_INCIDENCE = "GET_INCIDENCE";
export const ADD_INCIDENCE_COMMENTS = "ADD_INCIDENCE_COMMENTS";
export const GET_INCIDENCE_COMMENTS = "GET_INCIDENCE_COMMENTS";

export const LOAD_GROUP_TABLE = "LOAD_GROUP_TABLE";
export const LOAD_GROUPS = "LOAD_GROUPS";
export const LOAD_GROUP_ATHLETES_TABLE = "LOAD_GROUP_ATHLETES_TABLE";
export const GET_SPORT_SCHOOL_ATHLETES_BY_DATE = "GET_SPORT_SCHOOL_ATHLETES_BY_DATE";
export const GET_SPORT_SCHOOL_FEE = "GET_SPORT_SCHOOL_FEE";
export const GET_SPORT_SCHOOL_GROUPS = "GET_SPORT_SCHOOL_GROUPS";
export const GET_SPORT_SCHOOLS_GROUPS = "GET_SPORT_SCHOOLS_GROUPS";
export const GROUP_SCHEDULES = "GROUP_SCHEDULES";
export const GROUP_ATHLETES = "GROUP_ATHLETES";
export const GROUP_A_ATHLETES = "GROUP_A_ATHLETES";
export const GROUP_B_ATHLETES = "GROUP_B_ATHLETES";
export const ADD_GROUP = "ADD_GROUP";
export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_A = "GET_GROUP_A";
export const GET_GROUP_B = "GET_GROUP_B";
export const DELETE_GROUP = "DELETE_GROUP";
export const SEND_GROUP_COMMUNICATION = "SEND_GROUP_COMMUNICATION";
export const DOWNLOAD_GROUP_ATHLETES = "DOWNLOAD_GROUP_ATHLETES";
export const DOWNLOAD_GROUP_ATTENDANCE = "DOWNLOAD_GROUP_ATTENDANCE";
export const SWAP_GROUPS_ATHLETES = "SWAP_GROUPS_ATHLETES";
export const SWAP_GROUP_ATHLETES = "SWAP_GROUP_ATHLETES";


export const LOAD_TRAINER_TABLE = "LOAD_TRAINER_TABLE";
export const LOAD_TRAINERS = "LOAD_TRAINERS";
export const ADD_TRAINER = "ADD_TRAINER";
export const GET_TRAINER = "GET_TRAINER";
export const DELETE_TRAINER = "DELETE_TRAINER";
export const DOWNLOAD_TRAINERS = "DOWNLOAD_TRAINERS";

export const LOAD_USER_TABLE = "LOAD_USER_TABLE";
export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const GET_USER_STATISTICS = "GET_USER_STATISTICS";
export const CURRENT_USER_ID = "CURRENT_USER_ID";

export const LOAD_SCHEDULE_TABLE = "LOAD_SCHEDULE_TABLE";
export const LOAD_SCHEDULES = "LOAD_SCHEDULES";
export const GET_SCHEDULE = "GET_SCHEDULE";
export const ADD_SCHEDULE = "ADD_SCHEDULE";

export const LOAD_FEE_TYPES = "LOAD_FEE_TYPES";
export const GET_FEE_TYPE = "GET_FEE_TYPE";
export const ADD_FEE_TYPE = "ADD_FEE_TYPE";

export const LOAD_FAMILY_TABLE = "LOAD_FAMILY_TABLE";
export const ADD_FAMILY = "ADD_FAMILY";
export const GET_FAMILY = "GET_FAMILY";

export const DELETE_SPORTDATA = "DELETE_SPORTDATA";

export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";

export const API_CALL = "API_CALL";

export const ADD_OBSERVATIONS = "ADD_OBSERVATIONS";
export const DELETE_OBSERVATION = "DELETE_OBSERVATION";

export const ADD_RECEIPT = "ADD_RECEIPT";
export const ADD_RECEIPT_ATHLETE = "ADD_RECEIPT_ATHLETE";
export const VALIDATE_RECEIPT = "VALIDATE_RECEIPT";
export const LOAD_RECEIPT_TABLE = "LOAD_RECEIPT_TABLE";
export const GET_RECEIPT = "GET_RECEIPT";
export const DELETE_RECEIPT = "DELETE_RECEIPT";
export const DELETE_RECEIPT_ATHLETES = "DELETE_RECEIPT_ATHLETES";
export const ADD_RECEIPT_ATHLETES = "ADD_RECEIPT_ATHLETES";
export const GET_RECEIPT_ATHLETE = "GET_RECEIPT_ATHLETE";
export const LOAD_RECEIPT_ATHLETE_TABLE = "LOAD_RECEIPT_ATHLETE_TABLE";
export const GET_RECEIPT_ATHLETE_OBSERVATIONS = "GET_RECEIPT_ATHLETE_OBSERVATIONS";
export const LOAD_RETURNED_RECEIPT_REGISTERED_ATHLETE_TABLE = "LOAD_RETURNED_RECEIPT_REGISTERED_ATHLETE_TABLE";
export const LOAD_RETURNED_RECEIPT_UNREGISTERED_ATHLETE_TABLE = "LOAD_RETURNED_RECEIPT_UNREGISTERED_ATHLETE_TABLE";
export const LOAD_NOT_PAID_RECEIPT_REGISTERED_ATHLETE_TABLE = "LOAD_NOT_PAID_RECEIPT_REGISTERED_ATHLETE_TABLE"
export const LOAD_NOT_PAID_RECEIPT_UNREGISTERED_ATHLETE_TABLE = "LOAD_NOT_PAID_RECEIPT_UNREGISTERED_ATHLETE_TABLE"
export const PAY_RECEIPT_ATHLETE = "PAY_RECEIPT_ATHLETE"; 
export const DOWNLOAD_RECEIPT_ATHLETES = "DOWNLOAD_RECEIPT_ATHLETES";
export const LOAD_RECEIPTS = "LOAD_RECEIPTS";
export const ADD_RECEIPT_ATHLETE_OBSERVATIONS = "ADD_RECEIPT_ATHLETE_OBSERVATIONS"
export const DELETE_RECEIPT_ATHLETE_OBSERVATION = "DELETE_RECEIPT_ATHLETE_OBSERVATION"

export const ADD_FEE = "ADD_FEE";

export const UPLOAD_LICENSES = "UPLOAD_LICENSES"